import { useState, useRef } from "preact/hooks"
import React, { forwardRef } from "preact/compat"
import { h, Fragment } from "preact"

import { useMediaQuery } from 'usehooks-ts';

import Carousel from 'nuka-carousel'

import { HiArrowDown, HiArrowRight, HiCheckCircle, HiOutlineQuestionMarkCircle, HiChevronLeft, HiChevronRight, HiEnvelope } from 'react-icons/hi2';
import { SiFacebook, SiInstagram, SiSnapchat, SiTelegram, SiTiktok, SiTwitter, SiWhatsapp } from 'react-icons/si';
import { IoIosShareAlt } from 'react-icons/io';

import { Disclosure, Tab, Transition } from '@headlessui/react';
import { useForm } from "react-hook-form";
import Navbar from "./components/navbar";
import YDialog from "./components/YDialog";
import YButton from "./components/YButton";

const shareObj = {url: "https://youther.com.au"}





interface YCheckBoxProps {
	className?: string,
	name?: string,
	onChange?: React.ChangeEventHandler<HTMLInputElement>,
	onBlur?: React.FocusEventHandler,
	children: React.ReactNode,
}

const YCheckBox = forwardRef<HTMLInputElement, YCheckBoxProps>((props, ref) => (
	<label className={props.className}>
		<input className="peer sr-only" name={props.name} onChange={props.onChange} onBlur={props.onBlur} ref={ref} type="checkbox"/>
		<div className="inline-block w-5 h-5 bg-brandbg-100 dark:bg-brandbg-800 translate-y-1 mr-1 rounded-md shadow-sm peer-hover:shadow peer-hover:bg-brandbg-50 dark:peer-hover:bg-brandbg-700 peer-checked:bg-brand-500 dark:peer-checked:bg-brand-200 peer-checked:peer-hover:bg-brand-400 dark:peer-checked:peer-hover:bg-brand-100 peer-checked:[&>:first-child>:first-child]:stroke-dashoffset-0 transition-[shadow,background] duration-200">
			<svg className="w-5 h-5 stroke-white dark:stroke-black" viewBox="5 5 14 14" strokeWidth="1.5" fill="none" strokeLinecap="round" strokeLinejoin="round">
				<path d="M9 12l2 2l4 -4" strokeDasharray="8.485280990600586" strokeDashoffset="8.485280990600586" strokeLinecap="round" className="transition-[stroke-dashoffset] duration-200">
				</path>
			</svg>
		</div>
		{props.children}
	</label>
))

YCheckBox.displayName = "YCheckBox"



type WaitingPageFormData = {
	name: string,
	email: string,
	wantToPostJobs: boolean,
	wantToDoJobs: boolean,
}

function WaitingPageForm({
	done,
}: {
	done: () => void;
}) {

	let inputClasses = "block w-full transition-[border-color] border-brandbg-200 focus:border-brand-400 focus:outline-none border-b-2 bg-transparent"

	const [errorMessage, setErrorMessage] = useState<string|null>(null)
	const [waiting, setWaiting] = useState<boolean>(false)

	const { register, handleSubmit, watch, formState: { errors } } = useForm();
	const onSubmit = async(data: WaitingPageFormData) => {
		setWaiting(true)
		setErrorMessage(null);

		const response = await fetch("/api/preregister", {
			method: "POST",
			headers: { "Content-Type": "application/json" },
			body: JSON.stringify(data),
		})


		setWaiting(false)
		if (response.ok) {
			done()
		} else {
			try {
				setErrorMessage((await response.json()).message)
			} catch {
				setErrorMessage(response.statusText)
			}
		}

	}

	return (
		<form className={''} onSubmit = {handleSubmit(onSubmit)} noValidate>
			<label className="block mb-2 mt-4 text-xl">
				Name
				<input className={inputClasses} aria-label="Name" {...register("name")} />
			</label>

			<label className="block mb-2 mt-4 text-xl">
				Email
				<input className={inputClasses} aria-label="e-mail" type="email" {...register("email", { required: "E-Mail is required.", pattern: {value: /^\S+@\S+$/, message: "Invalid email"} })}/>
				{errors.email &&
					<span role="alert" className="text-red-600">{errors.email.message}</span>
				}
			</label>

			<p className="text-xl mt-4 mr-4 inline">
				I am interested in:
			</p>

			<YCheckBox className="text-lg mr-2 block xl:inline" {...register("wantToPostJobs")} >
				Posting Jobs
			</YCheckBox>
			<YCheckBox className="text-lg block xl:inline" {...register("wantToDoJobs")}>
				Doing Jobs
			</YCheckBox>

			<p className='mt-2'>We will send you an email when Youther launches.</p>


			<button disabled={waiting} className="w-full rounded-full text-xl
			bg-brand-600 text-brandbg-50
			disabled:opacity-75
			dark:bg-brand-300 dark:text-brandbg-800
			shadow-gray-700 shadow-sm hover:enabled:shadow-md hover:enabled:shadow-gray-700
			py-2 my-2
			transition" type="submit">{ waiting ? "Loading..." : "Sign Up"}</button>


			{errorMessage && <p className="text-red-500">{errorMessage}</p>}

		</form>
	)
}



function MarketingList(props: {points: string[]}) {
	return (
		<ul className='whitespace-nowrap ml-auto mr-auto w-fit text-lg sm:text-2xl space-y-2'>
			{props.points.map((value, idx) => 
				<li key={idx}>
					<HiCheckCircle className='inline-block align-top h-5 sm:h-6 translate-y-[0.2rem] mr-2 text-brand-600 dark:text-brand-200'/>
					<div className='inline-block whitespace-pre-wrap'>
						{value}
					</div>
				</li>
			)}
		</ul>
	)
}

function ThreeStepProcess(props: {steps: [string, string, string]}) {
	const biggerThanPad = useMediaQuery('(min-width: 1024px)')

	return (
		<>
			{ biggerThanPad ?
				<ol className='flex flex-row justify-start items-stretch gap-4'>
					<li className='max-w-xl text-4xl bg-brandbg-50 dark:bg-brandbg-700 px-8 py-4 shadow-xl rounded-xl'>
						<span className='text-brand-600 dark:text-brand-400'>① </span>
						{props.steps[0]}
					</li>
					<HiArrowRight className='h-16 self-center'/>
					<li className='max-w-xl text-4xl bg-brandbg-50 dark:bg-brandbg-700 px-8 py-4 shadow-xl rounded-xl'>
						<span className='text-brand-600 dark:text-brand-400'>② </span>
						{props.steps[1]}
					</li>
					<HiArrowRight className='h-16 self-center'/>
					<li className='max-w-xl text-4xl bg-brandbg-50 dark:bg-brandbg-700 px-8 py-4 shadow-xl rounded-xl'>
						<span className='text-brand-600 dark:text-brand-400'>③ </span>
						{props.steps[2]}
					</li>
				</ol>
			:
				<ol className='pt-4 text-base sm:text-2xl space-y-1 [&>li]:before:text-brand-600 [&>li]:dark:before:text-brand-200'>
					<li className='before:content-["①_"]'>
					    {props.steps[0]}
					</li>
					<HiArrowDown className='h-5 sm:h-7 mx-auto xs:ml-32 text-brand-600 dark:text-brand-200'/>
					<li className='before:content-["②_"]'>
						{props.steps[1]}
					</li>
					<HiArrowDown className='h-5 sm:h-7 mx-auto xs:ml-32 text-brand-600 dark:text-brand-200'/>
					<li className='before:content-["③_"]'>
						{props.steps[2]}
					</li>
				</ol>
			}
		</>
	)
}

function MyTab(props: {children: React.ReactNode}) {
	return (
	<Tab className={({selected}) => `
			flex-1 rounded-lg py-2 md:py-4 md:text-xl md:leading-5 transition-all duration-200
			${selected ?
				"bg-white dark:bg-brandbg-900 text-brand-800 dark:text-white shadow-xl" :
				"hover:bg-white/40 dark:hover:bg-black/[0.12] text-brandbg-700 dark:text-brandbg-100 hover:text-black hover:dark:text-white"
			}
		`} >
			{props.children}
		</Tab>
	)
}


const brands: {name: string, url: string, Icon: (props: SVGProps<SVGSVGElement>) => JSX.Element}[] = [
	{name: "Facebook", url: "https://www.facebook.com/sharer/sharer.php?u=%u", Icon: SiFacebook},
	{name: "Whatsapp", url: "https://wa.me/?text=%u", Icon: SiWhatsapp},
	{name: "Telegram", url: "https://t.me/share/url?url=%u", Icon: SiTelegram},
	{name: "Email", url: "mailto:?body=%u&subject=Youther", Icon: HiEnvelope},
	{name: "Twitter", url: "https://twitter.com/intent/tweet?url=%u", Icon: SiTwitter},
	{name: "Snapchat", url: "https://www.snapchat.com/scan?attachmentUrl=%u", Icon: SiSnapchat},
]



const MainPage = () => {
	/*
	const fadeInImage = (e, H) => {
		const observer = new IntersectionObserver(entries => {
			entries.forEach(entry => setVisible(entry.isIntersecting));
		});
		observer.observe(img);
		return () => observer.unobserve(img);
	};*/

	const biggerThanPad = useMediaQuery('(min-width: 1024px)')
	const biggerThanPhone = useMediaQuery('(min-width: 640px)')

	const [errorMsg, setErrorMsg] = useState("")
	const [done, setDone] = useState(false)
	const [waiting, setWaiting] = useState(false)

	const [shareVisible, setShareVisible] = useState(false)
	const [contactVisible, setContactVisible] = useState(false)

	const signupContainerRef = useRef<HTMLDivElement>()



	const share = async() => {
		try {
			if (typeof navigator.canShare !== "undefined" && navigator.canShare(shareObj)) {
				await navigator.share(shareObj)
			} else {
				setShareVisible(true)
			}
		} catch (e) {
			setShareVisible(true)
		}
	}

	return (
		<>
			<Navbar mainPage={true}/>
			<main className="flex-1 min-h-0">
			<YDialog
				title="Share Youther."
				open={shareVisible}
				setOpen={setShareVisible}
				buttonsDef={[{type: "outline", text: "Done", action: () => {setShareVisible(false)}}]}
				>
				<div className='flex flex-row mt-4 overflow-x-auto'>
					{ brands.map((item, idx) => {
						const Icon = item.Icon
						console.log(JSON.stringify(item))
						return (
							<div className='w-20 min-w-20 shrink-0 flex flex-col items-center cursor-pointer' key={idx}
								onClick={() => window.open(item.url.replace("%u", encodeURIComponent(("https://youther.com.au"))), undefined, "noreferrer")}>
								<Icon className='h-12 w-12' />
								<p className='text-xs text-brandbg-600 dark:text-brandbg-200 mt-1 text-center'>{item.name}</p>
							</div>
						)
					})
					}
				</div>
			</YDialog>
			<div className='snap-start flex flex-row h-[calc(100vh-3.5rem)] mt-14 items-top justify-end relative'>

				{/*<div className='absolute top-0 left-0 z-[-5] h-full w-full bg-gradient-radial from-[#00000088] to-[#ffffff44]' />*/}

				<img
					className='absolute top-0 left-0 w-full h-full object-cover object-[22%_100%]'
					src='/images/image_1.jpg'
					alt="Picture of a teen trimming a bush"
					placeholder="blurred"
				/>

				<div
					className='z-10 sm:pr-8 lg:px-20 max-w-4xl text-center sm:text-end text-white flex flex-col justify-start items-center sm:items-end sm:bg-gradient-to-r from-transparent to-[#00000088]'
					style={{
						'--tw-gradient-stops': 'var(--tw-gradient-from), var(--tw-gradient-to) 25%'
					}}

					>
					<div
						className='pt-5 pb-8 sm:pt-8 bg-gradient-to-t sm:bg-none '
						style={{
							'--tw-gradient-stops': 'var(--tw-gradient-from), var(--tw-gradient-to) 25%'
						}}

						>
						<h1 className='text-6xl sm:text-7xl'><span className='text-brand-200'>Youth</span>er.</h1>
						<h1 className='text-xl sm:text-[2rem] sm:leading-10 mt-6 font-medium'>Get jobs done with the help of teens in your community.</h1>
					</div>
					<YButton onClick={() => {signupContainerRef.current?.scrollIntoView({behavior: 'smooth'})}} className="px-4 w-[58vw] sm:w-auto sm:px-8 py-2 text-xl justify-self-stretch mt-auto mb-24 sm:my-6 !text-black !bg-brand-100">
						Sign Up
					</YButton>
				</div>


			</div>

			<div className='snap-start flex flex-col items-center justify-center relative'>
				<div className='max-w-6xl mt-8 sm:mt-10 xl:mt-16 mb-8 mx-8 sm:mx-12 space-y-4'>
					<h2 className='text-4xl text-center'>How it works</h2>
					<p>Youther is a social enterprise platform in which anyone can post jobs that they need done or require help doing for an appropriate price. A corresponding workforce of youth (aged 15+) then responds with offers to the jobs that they are best suited to work, leaving the poster with a great selection of affordable workers, eager to complete the tasks at hand.</p>
					{ biggerThanPhone ?
						<p>We aim to provide community members with a workforce of lively and affordable workers that are eager to do any job, big or small. Youther provides a platform that you can feel good about using, a platform that gets jobs done and provides youthful workers with a chance to work independently, gain experience, and make their own income.</p>
					:
					<Disclosure className="bg-brandbg-50 dark:bg-brandbg-800 rounded-xl shadow-sm">
						{({ open }) => (
							<>
								<Disclosure.Button className={
									`bg-brand-50/20 text-brand-900 hover:text-black hover:bg-brand-100/30
									dark:bg-brand-800/50 dark:text-brandbg-100 dark:hover:text-white dark:hover:bg-brand-700/70
									transition-all rounded-xl px-4 py-1 w-full flex justify-between gap-2`}>
									<span>
										Our mission
									</span>
									<svg xmlns="http://www.w3.org/2000/svg"
										fill="none"
										viewBox="0 0 24 24"
										strokeWidth="1.5"
										stroke="currentColor"
										className="w-5 h-5 text-brand-500 dark:text-brand-300 shrink-0"
										>
											<path className={`transition-transform origin-center ${open && "rotate-90"}`} strokeLinecap="round" strokeLinejoin="round" d="M12 4.5v15" />
											<path strokeLinecap="round" strokeLinejoin="round" d="M19.5 12 h-15" />
									</svg>

								</Disclosure.Button>
									
								<Transition
									enter="transition duration-100 ease-out"
									enterFrom="transform scale-95 opacity-0"
									enterTo="transform scale-100 opacity-100"
									leave="transition duration-75 ease-out"
									leaveFrom="transform scale-100 opacity-100"
									leaveTo="transform scale-95 opacity-0"
								>
									<Disclosure.Panel className="p-4 whitespace-pre-line">
					<p>We aim to provide community members with a workforce of lively and affordable workers that are eager to do any job, big or small. Youther provides a platform that you can feel good about using, a platform that gets jobs done and provides youthful workers with a chance to work independently, gain experience, and make their own income.</p>
									</Disclosure.Panel>
								</Transition>
							</>
						)}
					</Disclosure>
					}
					<p className='mt-14'>A small fee of $2.80 to assign a worker to a job is a no brainer. Get jobs done easier, cheaper and with more satisfaction using Youther!</p>
				</div>
				{ biggerThanPad ?
					<div className='flex-1 relative flex'>
						<div className='grid grid-cols-3 max-w-3xl gap-6 [&>img]:rounded-xl [&>img]:shadow-xl mx-8 mt-10 mb-0'>
							<img
								className={`row-span-2`}
								src='/images/image_2a.jpg'
								alt="Picture of a teen mowing a lawn"
							/>
							<img
								className={``}
								src='/images/image_2b.jpg'
								alt="Picture of a teen washing a car"
							/>
							<img
								className={`row-span-2`}
								src='/images/image_2c.jpg'
								alt="Picture of a teen watering a bush"
							/>
							<img
								className={`row-span-2`}
								src='/images/image_2d.jpg'
								alt="Picture of a teen walking dogs"
							/>

						</div>
						<div className='absolute top-0 left-0 z-[-1] w-full h-full'>
							<div className='w-[25%] h-[30%] bg-brand-50 rounded-xl absolute top-[3%]'/>
							<div className='w-[30%] h-[30%] bg-brand-100 rounded-xl absolute top-[32.5%] left-[25%]'/>
							<div className='w-[22.5%] h-[27%] bg-brand-50 rounded-xl absolute top-[0] left-[50%]'/>
							<div className='w-[20%] h-[25%] bg-brand-200 rounded-xl absolute top-[4.5%] right-0'/>
							<div className='w-[20%] h-[25%] bg-brand-100 rounded-xl absolute bottom-[12%] left-[47%]'/>
							<div className='w-[20%] h-[20%] bg-brand-200 rounded-xl absolute top-[40%] right-[2%]'/>
						</div>
					</div>
					:
					<div className='flex-grow min-h-0'>
						<Carousel 
							wrapAround
							className='aspect-[14/12]'
							autoplay
							autoplayInterval={3000}
							renderCenterLeftControls={({ previousDisabled, previousSlide }) => (
								<YButton type='filled' className='!bg-brandbg-900/80 !text-white backdrop-blur p-2 mr-2' onClick={previousSlide} disabled={previousDisabled}>
									<HiChevronLeft/>
								</YButton>
							)}
							renderCenterRightControls={({ nextDisabled, nextSlide }) => (
								<YButton type='filled' className='!bg-brandbg-900/80 !text-white backdrop-blur p-2 mr-2' onClick={nextSlide} disabled={nextDisabled}>
									<HiChevronRight/>
								</YButton>
							)}

							>
							<img
								className='object-[0_60%] h-full object-cover'
								src='../images/image_2a.jpg'
								alt="Picture of a teen mowing a lawn"
							/>
							<img
								className='object-[80%_0] h-full object-cover'
								src='../images/image_2b.jpg'
								alt="Picture of a teen washing a car"
							/>
							<img
								className='object-[0_40%] h-full object-cover'
								src='../images/image_2c.jpg'
								alt="Picture of a teen watering a bush"
							/>
							<img
								className='object-[0_60%] h-full object-cover'
								src='../images/image_2d.jpg'
								alt="Picture of a teen walking dogs"
							/>
						</Carousel>
				</div>
				}
			</div>
			<div className='border-t border-brandbg-400 pt-16 snap-start flex flex-row xl:h-screen pb-8 items-center relative'>
				<div className='flex flex-col items-center w-full h-full'>
					<Tab.Group>
						<Tab.List className="flex space-x-1 rounded-xl bg-brandbg-50 dark:bg-brandbg-800 w-[calc(100%-32px)] md:w-96 p-1">
							<MyTab>Post jobs</MyTab>
							<MyTab>Do jobs</MyTab>
						</Tab.List>
						<Tab.Panels className='mx-8 pt-8 pb-16'>
							<Tab.Panel>
								<p className='max-w-5xl mb-6 ml-auto mr-auto text-center text-3xl'>
									A quick and easy way to get anything done.
								</p>
								<MarketingList points={[
									'Select the right worker for your job out of an eager workforce of youth.',
									'Quality assurance. Trust the worker doing your job with our safe review system.',
									'Save money on labour costs while supporting the youth',
									'No need to spend your time on simple jobs, make time for what matters.',
									'Support the youth. Support the future.'

								]}/>
								<p className='max-w-2xl mb-6 mt-14 ml-auto mr-auto text-center text-2xl'>
									Get what you need done in a few simple steps.
								</p>
								<ThreeStepProcess steps={['Post a job with a relevant description', 'Choose the perfect worker and pay a small $2.80 service fee to commence the job', 'Enjoy the work done and review the worker']}/>
							</Tab.Panel>
							<Tab.Panel>
								<p className='max-w-5xl mb-6 ml-auto mr-auto text-center text-3xl'>
									A fun, easy way to earn income for young aspiring individuals.
								</p>
								<MarketingList points={[
									'Earn money doing a variety of jobs you enjoy',
									'Work whenever you want',
									'Gain experience and real life skills',
									'A fun and easy save up for the dream or make some quick cash',
									'Grow in financial and personal independence.'

								]}/>
								<p className='max-w-2xl mb-6 mt-14 ml-auto mr-auto text-center text-2xl'>
									Complete jobs in few simple steps.
								</p>
								<ThreeStepProcess steps={['Find a job that appeals to you', 'Post an offer and get accepted by the poster', 'Complete the job, get paid and review the poster']}/>
							</Tab.Panel>
						</Tab.Panels>
					</Tab.Group>
				</div>
			</div>
			<div className='relative sm:h-screen h-fit p-1 sm:p-8' style={{clipPath: "inset(0)"}}>
				<img
					className='fixed top-0 left-0 h-screen w-screen -z-10 pointer-events-none object-cover object-[38%_28%]'
					src='../images/image_3.jpg'
					alt="Picture of a teen carrying a plant pot"
					placeholder="blurred"
				/>
				<div className='max-w-5xl pb-4 sm:pb-8 pt-4 bg-brandbg-900 shadow-2xl bg-opacity-60 backdrop-blur pl-6 sm:px-12 ml-[50%] sm:ml-0 sm:float-right text-white rounded-xl'>
					<h2 className='text-xl sm:text-4xl mb-6 font-medium mr-4'>What jobs can I do/get done?</h2>
					<ul className="sm:columns-3 gap-x-12 w-fit text-sm sm:text-2xl [&>li]:snap-start [&>li]:snap-always [&>li];font-light list-disc max-h-full px-4 text-brandbg-50">
						<li>Tutoring</li>
						<li>Lawn mowing</li>
						<li>Removal</li>
						<li>Dog walking</li>
						<li>Car wash</li>
						<li>Party entertainment</li>
						<li>Gardening</li>
						<li>Move furniture</li>
						<li>Set up events</li>
						<li>Item delivery</li>
						<li>Photography</li>
						<li>Take out the garbage</li>
						<li>Babysitting</li>
						<li>Cleaning</li>
						<li>Decoration</li>
						<li>Painting</li>
						<li>Window cleaning</li>
						<li>Computer assistance</li>
						<li>Power washing</li>
						<li>Assemble furniture</li>
						<li>Household assistance</li>
						<li>Decluttering</li>
						<span className='mt-8 block font-medium text-white'>No job is too big or small – anything you need done, a youthful worker is out there ready and waiting to do it!</span>
					</ul>
				</div>
			</div>
			<div className='border-t border-brandbg-400 snap-start flex flex-col md:h-screen relative justify-center gap-x-8'>
				<div className="flex flex-col lg:flex-row items-center justify-around mt-auto">
					<div className='max-w-5xl mt-8 text-center mx-4'>
						<h2 className='text-3xl sm:text-4xl md:text-6xl font-medium'>Youther will be launching soon.</h2>
						<p className='mt-2'>Sign up your email today so that you are notified as soon as Youther launches</p>
						<p className='text-xl mt-6 sm:mt-16 mb-8'> <b className='text-brand-500 dark:text-brand-300'>{2381}</b> people have already joined!</p>
						<div className='gap-8 inline-grid grid-cols-[1fr,1fr] items-center min-w-0'>
							<YButton type="outline" onClick={share} className='flex flex-row gap-4 text-2xl items-center px-6 py-3 '>
								<span className="inline-block">
									Share
								</span>
								<IoIosShareAlt className='' />
							</YButton>
							<YButton type="outline" href="/faq" className='flex flex-row gap-4 text-2xl items-center justify-between px-6 py-3 min-w-0'>
								<span className="inline-block">
									FAQ
								</span>
								<HiOutlineQuestionMarkCircle className='' />
							</YButton>
						</div>
					</div>
					<div className='mt-8 mb-4 xl:w-full xl:max-w-2xl mx-4 sm:min-w-[24rem]' ref={signupContainerRef}>
						{done ?
							<>
								<h2 className="text-3xl">Thanks for joining Youther!</h2>
								<p className="text-xl">We will notify you when we launch</p>
								<YButton type='flat' onClick={share}>
									Why not share Youther?
								</YButton>
							</>
							:
							<WaitingPageForm
							done={() => {setDone(true)}}
						/>
						}
					</div>
				</div>
				<div className='w-full mt-auto bg-black py-4 space-y-1 text-white'>
					<div className='w-fit mx-auto [&>*]:inline-block space-x-4 text-2xl'>
						<a href="https://www.instagram.com/youther_au/">
							<SiInstagram/>
						</a>
						<a href="https://www.facebook.com/people/Youther/100089093740279/">
							<SiFacebook/>
						</a>
						<a href="https://www.tiktok.com/@youther_au">
							<SiTiktok />
						</a>
					</div>
					<div className='mx-auto text-xs w-fit'>
						© 2022-{new Date().getFullYear()} Youther Pty Ltd.
					</div>
				</div>
			</div>
			</main>
		</>
	)
}

export default MainPage
