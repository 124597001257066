import { Disclosure, Transition } from '@headlessui/react'
import { HiChevronUp } from 'react-icons/hi2'
import { h, Fragment } from 'preact'
import Navbar from './components/navbar'


const faqs = {
	'How do I post a job or a job offer?': `Posting a job is very easy, just outline all the necessary details, such as giving an approximate location, specifying a time period for completion, and providing an initial price offering.

In response, after finding a suitable job, a worker can post an offer. Generally the offer would explain why an individual is suitable for a job but can also include other factors such as lower price to the original job post. It is vital that users posting an offer are able to commit to all the requirements of the job, such as being available at the specific time period for completion, and be willing to work for the price that was the in final offer.`,
	'Can I cancel a job after confirming it?': `After the poster accepts an offer from a worker, the job is considered to be confirmed. However, there is still a possibility for either party to cancel as outlined below:
If the original poster cancels, generally they will lose the fee they have paid, however this can always be disputed through our customer support team. On the other hand a worker who cancels a confirmed job will have their cancellation noted in a completion rate percentage, that may be visible to future job posters.`,
	'Why the $2.80 fee?': 'The $2.80 fee is a small fee to connect you with the worker and keep the platform alive. After paying the $2.80 fee it is all between you and the worker.',
	'How do we ensure that teens on our platform are safe?': 'Youther makes every effort to ensure the platform is as safe as possible. This includes our active content moderation and reporting system. However to maintain safety we also ensure all teens have a responsible parent or guardian that signs permission for the individual under their care to use our platform and ensure all work is suitable.',
	'What happens if I am not satisfied with the job or the worker doesn’t complete the job?': `If a user is not satisfied with the work performed by a worker, they should first try to resolve the issue directly with the worker. If the issue cannot be resolved, the user should contact our customer support team by emailing at contact@youther.com.au, providing detailed information about the job in question and explaining the reasons for their dissatisfaction. Our customer support team will then review the case and take appropriate action.
It is also important to note, using our review system provides other users as well as Youther itself with valuable insights into the quality of service of particular users, preventing future issues.`,
//	'What is Youther?': 'Youther is a platform in which people of all ages can post jobs that they need done or require help doing. A corresponding workforce of youth (aged 15-19) then respond with offers to the jobs that they are best suited to work, leaving the poster with a great selection of affordable workers, eager to complete the tasks at hand'
}

export default function FAQ() {

	return (
		<>
			<Navbar/>
			<h1 className='ml-8 sm:ml-12 text-4xl mt-8 mb-2'>Frequently asked questions</h1>
			<div className='max-w-6xl mx-auto space-y-4 px-8 py-6'>
				{ Object.entries(faqs).map(([q, a], idx) => (
					<Disclosure key={idx} as="div" className="bg-brandbg-50 dark:bg-brandbg-800 rounded-xl shadow-xl">
						{({ open }) => (
							<>
								<Disclosure.Button className={
									`bg-brand-50/50 text-brand-900 hover:text-black hover:bg-brand-100/70
									dark:bg-brand-800/50 dark:text-brandbg-100 dark:hover:text-white dark:hover:bg-brand-700/70
									transition-all rounded-xl p-4 w-full flex justify-between gap-2`}>
									<span>
										{q}
									</span>
									<HiChevronUp
										className={`${
											open ? 'rotate-180 transform' : ''
											} h-5 w-5 text-brand-500 dark:text-brand-300 transition-transform shrink-0`}
									/>
								</Disclosure.Button>
									
								<Transition
									enter="transition duration-100 ease-out"
									enterFrom="transform scale-95 opacity-0"
									enterTo="transform scale-100 opacity-100"
									leave="transition duration-75 ease-out"
									leaveFrom="transform scale-100 opacity-100"
									leaveTo="transform scale-95 opacity-0"
								>
									<Disclosure.Panel className="p-4 whitespace-pre-line">
										{a}
									</Disclosure.Panel>
								</Transition>
							</>
						)}
					</Disclosure>
				))}
			</div>
		</>
	)
}
